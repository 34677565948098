// obtain plugin
const cc = initCookieConsent();
// const checkboxPolicy = document.querySelector('#politica-privacidad');

// run plugin with your configuration
cc.run({
    current_lang: 'es',
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false

    // mode: 'opt-out',                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
      // callback triggered only once
    },

    onAccept: function (cookie) {
      // ...
      // checkboxPolicy.checked = true;
    },

    onChange: function (cookie, changed_preferences) {
      // ...
      // checkboxPolicy.checked = true;
    },


    gui_options: {
      consent_modal: {
        layout: 'box',               // box/cloud/bar
        position: 'bottom center',     // bottom/middle/top + left/right/center
        transition: 'slide',           // zoom/slide
        swap_buttons: false            // enable to invert buttons
      },
      settings_modal: {
        layout: 'box',                 // box/bar
        // position: 'left',           // left/right
        transition: 'slide'            // zoom/slide
      }
    },

    languages: {
      'en': {
        consent_modal: {
          title: 'We use cookies!',
          description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
          primary_btn: {
            text: 'Accept all',
            role: 'accept_all'              // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Reject all',
            role: 'accept_necessary'        // 'settings' or 'accept_necessary'
          }
        },
        settings_modal: {
          title: 'Cookie preferences',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject all',
          close_btn_label: 'Close',
          cookie_table_headers: [
            {col1: 'Name'},
            {col2: 'Domain'},
            {col3: 'Expiration'},
            {col4: 'Description'}
          ],
          blocks: [
            {
                title: 'Cookie usage 📢',
                description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
            }, {
                title: 'Strictly necessary cookies',
                description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                }
            }, {
                title: 'Performance and Analytics cookies',
                description: 'These cookies allow the website to remember the choices you have made in the past',
                toggle: {
                    value: 'analytics',     // your cookie category
                    enabled: false,
                    readonly: false
                },
                cookie_table: [             // list of all expected cookies
                    {
                        col1: '^_ga',       // match all cookies starting with "_ga"
                        col2: 'google.com',
                        col3: '2 years',
                        col4: 'description ...',
                        is_regex: true
                    },
                    {
                        col1: '_gid',
                        col2: 'google.com',
                        col3: '1 day',
                        col4: 'description ...',
                    }
                ]
            }, {
                title: 'Advertisement and Targeting cookies',
                description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                toggle: {
                    value: 'targeting',
                    enabled: false,
                    readonly: false
                }
            }, {
                title: 'More information',
                description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
            }
          ]
        }
      },
      'es': {
        consent_modal: {
          title: '¡Usamos cookies!',
          description: 'Hola, este sitio web utiliza cookies esenciales para garantizar su correcto funcionamiento y cookies de seguimiento para comprender cómo interactúas con él. Este último se fijará sólo después del consentimiento. <button type="button" data-cc="c-settings" class="cc-link">Dejame elegir</button>',
          primary_btn: {
            text: 'Aceptar todo',
            role: 'accept_all'              // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            // text: 'Reject all',
            // role: 'accept_necessary'        // 'settings' or 'accept_necessary'
            text: 'Ajustes',
            role: 'settings'        // 'settings' or 'accept_necessary'
          }
        },
        settings_modal: {
          title: 'Preferencias de cookies',
          save_settings_btn: 'Guardar ajustes',
          accept_all_btn: 'Aceptar todo',
          reject_all_btn: 'Rechazar todo',
          close_btn_label: 'Cerrar',
          cookie_table_headers: [
            {col1: 'Nombre'},
            {col2: 'Dominio'},
            {col3: 'Expiración'},
            {col4: 'Descripción'}
          ],
          blocks: [
            {
              title: 'Uso de cookies 📢',
              description: 'Utilizamos cookies para garantizar las funcionalidades básicas del sitio web y mejorar su experiencia en línea. Puedes elegir para cada categoría optar por participar o no participar cuando lo desees. Para obtener más detalles relacionados con las cookies y otros datos confidenciales, lee el apartado Privacidad / Política de Seguridad alojado en el final de la página.'
            }, {
              title: 'Cookies estrictamente necesarias',
              description: 'Estas cookies son esenciales para el correcto funcionamiento de nuestro sitio web. Sin estas cookies, el sitio web no funcionaría correctamente.',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
              }
            }, {
              title: 'Cookies de rendimiento y análisis',
              description: 'Estas cookies permiten que el sitio web recuerde las elecciones que has realizado en el pasado.',
              toggle: {
                value: 'analytics',     // your cookie category
                enabled: false,
                readonly: false
              },
              cookie_table: [             // list of all expected cookies
                {
                  col1: '^_ga',       // match all cookies starting with "_ga"
                  col2: 'google.com',
                  col3: '2 años',
                  col4: 'Se usa para distinguir a los usuarios.',
                  is_regex: true
                },
                {
                  col1: '_gid',
                  col2: 'google.com',
                  col3: '24 horas',
                  col4: 'Se usa para distinguir a los usuarios.',
                }
              ]
            }, {
              title: 'Cookies publicitarias y de orientación',
              description: 'Estas cookies recopilan información sobre cómo usas el sitio web, qué páginas visitaste y en qué enlaces realizaste clic. Todos los datos son anónimos y no se pueden usar para identificarte.',
              toggle: {
                value: 'targeting',
                enabled: false,
                readonly: false
              }
            }, {
              title: 'Más información',
              description: 'Para cualquier consulta en relación con nuestra política de cookies y sus opciones, por favor <a class="cc-link" href="contactanos.php">Contáctanos</a>.',
            }
          ]
        }
      }
    }
});